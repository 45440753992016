import { ModelSelect, BasicSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import { format } from 'date-fns';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  name: 'AdvanceSearch',
  components: {
    ModelSelect,
    BasicSelect,
    DatePicker
  },
  watch: {
    selectedProject: function() {
      if (this.selectedProject.value) {
        this.lovFieldName = appStrings.FMSLOV.FMSPROJECT;
        this.lovId = this.selectedProject.value;
        this.getOtherLovByProjectId();
      }
    }
  },
  data() {
    return {
      vsetCode: null,
      setTimeout: null,
      showValueSetModal: false,
      dateRangePicker: {
        opens: true,
        autoApply: true,
        dateRange: null,
        dateFormat: appStrings.DATEFNSFORMAT
      },
      showCreateReceipt: false,
      loader: false,
      lovFieldName: '',
      lovId: '',
      keyValue: {
        value: null,
        text: null
      },
      advanceSearchForm: {
        leagalEntity: [
          {
            value: null,
            text: '--select--'
          }
        ],
        receiptAgainst: [
          {
            value: null,
            text: null
          }
        ],
        paymentMode: [
          {
            value: null,
            text: null
          }
        ],
        drawnOn: [
          {
            value: null,
            text: null
          }
        ],
        projectList: [
          {
            value: null,
            text: null
          }
        ],
        sectorList: [
          {
            value: null,
            text: null
          }
        ],
        pocketList: [
          {
            value: null,
            text: null
          }
        ],
        towerList: [
          {
            value: null,
            text: null
          }
        ],
        floorList: [
          {
            value: null,
            text: null
          }
        ],
        unitList: [
          {
            value: null,
            text: null
          }
        ],
        subUnitList: [
          {
            value: null,
            text: null
          }
        ],
        instrumentStatusList: [
          {
            value: null,
            text: null
          }
        ],
        accountNumList: [
          {
            value: null,
            text: null
          }
        ]
      },
      selectedReceiptAgainst: {
        value: null,
        text: null
      },
      selectedLeagalEntity: {
        value: null,
        text: null
      },
      selectedPaymentMode: {
        value: null,
        text: null
      },
      receiptNum: '',
      customerName: '',
      selectedDrawnOn: {
        value: null,
        text: null
      },
      selectedProject: {
        value: null,
        text: null
      },
      selectedSector: {
        value: null,
        text: null
      },
      selectedPocket: {
        value: null,
        text: null
      },
      selectedTower: {
        value: null,
        text: null
      },
      selectedFloor: {
        value: null,
        text: null
      },
      selectedUnit: {
        value: null,
        text: null
      },
      selectedSubUnit: {
        value: null,
        text: null
      },
      selectedInstrumentStatus: {
        value: null,
        text: null
      },
      selectedAccountNum: {
        value: null,
        text: null
      },
      instrumentStartAmount: null,
      instrumentEndAmount: null,
      isAdvanceSearchEnable: false,
      search: '',
      data: [],
      update: false,
      showAlert: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      stickyHeader: true,
      totalRows: null,
      filterOn: [],
      showModal: false,
      editMode: false,
      responseMsg: '',
      isSuccess: false,
      isFailed: false,
      creation_start_date: null,
      creation_end_date: null,
      gl_start_date: null,
      gl_end_date: null,
      value_start_date: null,
      value_end_date: null,
      valueDate: null,
      glDate: null,
      creationDate: null
    };
  },
  mounted() {
    this.getInstrumentStatus();
  },
  methods: {
    selectedTowerFun(towerVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSTOWER;
      this.lovId = towerVal.value;
      this.selectedTower = towerVal;
      this.getOtherLovByProjectId();
    },
    selectedFloorFun(floorVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSFLOOR;
      this.selectedFloor = floorVal;
      this.lovId = floorVal.value;
      this.getOtherLovByProjectId();
    },
    selectedUnitFun(unitVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSUNIT;
      this.selectedUnit = unitVal;
      this.lovId = unitVal.value;
      this.getOtherLovByProjectId();
    },
    selectedSubUnitFun(subUnitVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSSUBUNIT;
      this.selectedSubUnit = subUnitVal;
      this.lovId = subUnitVal.value;
      this.getOtherLovByProjectId();
    },
    getInstrumentStatus() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'RECEIPT_STATUS')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_set_dtl_id,
                text: type.value_meaning
              };
            });
            this.advanceSearchForm.instrumentStatusList = getValueAndText;
          }
        });
    },
    getProjectList(leId) {
      const payload = {
        typeOfProject: 'FMSPRJ',
        le_id: leId
      };
      this.$store.dispatch('fms/getProjectList', payload).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const getValueAndText = results.map(type => {
            return {
              value: type.proj_id,
              text: type.proj_name
            };
          });
          this.advanceSearchForm.projectList = getValueAndText;
        }
      });
    },
    getOtherLovByProjectId() {
      this.loader = true;
      const payload = {
        lovFieldName: this.lovFieldName,
        lovId: this.lovId
      };
      this.$store
        .dispatch('receipt/getOtherLovByLovFieldAndId', payload)
        .then(response => {
          this.loader = false;
          if (response && response.data && response.data.data) {
            const results = response.data.data;
            if (this.lovFieldName === appStrings.FMSLOV.FMSPROJECT) {
              this.getSectorList(results.sectors);
              this.getPocketList(results.pockets);
              this.getTowerList(results.towers);
              this.getFloorList(results.floors);
              this.getUnitList(results.units);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSTOWER) {
              this.getFloorList(results.floors);
              this.getUnitList(results.units);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSFLOOR) {
              this.getTowerList(results.towers);
              this.getUnitList(results.units);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSUNIT) {
              this.getTowerList(results.towers);
              this.getFloorList(results.floors);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSSUBUNIT) {
              this.getTowerList(results.towers);
              this.getFloorList(results.floors);
              this.getUnitList(results.units);
            }
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    getSectorList(sectorList) {
      if (sectorList && sectorList.length) {
        const valueAndTextArr = sectorList.map(type => {
          return {
            value: type.sector_id,
            text: type.sector_name
          };
        });
        this.advanceSearchForm.sectorList = valueAndTextArr;
        this.selectedSector =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedSector = this.keyValue;
        this.advanceSearchForm.sectorList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getPocketList(pocketList) {
      if (pocketList && pocketList.length) {
        const valueAndTextArr = pocketList.map(type => {
          return {
            value: type.pocket_id,
            text: type.pocket_name
          };
        });
        this.advanceSearchForm.pocketList = valueAndTextArr;
        this.selectedPocket =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedPocket = this.keyValue;
        this.advanceSearchForm.pocketList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getTowerList(towerList) {
      if (towerList && towerList.length) {
        const valueAndTextArr = towerList.map(type => {
          return {
            value: type.tower_id,
            text: type.tower_name
          };
        });
        this.advanceSearchForm.towerList = valueAndTextArr;
        this.selectedTower =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedTower = this.keyValue;
        this.advanceSearchForm.towerList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getFloorList(floorList) {
      if (floorList && floorList.length) {
        const valueAndTextArr = floorList.map(type => {
          return {
            value: type.floor_id,
            text: type.floor_name
          };
        });
        this.advanceSearchForm.floorList = valueAndTextArr;
        this.selectedFloor =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedFloor = this.keyValue;
        this.advanceSearchForm.floorList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getUnitList(unitList) {
      if (unitList && unitList.length) {
        const valueAndTextArr = unitList.map(type => {
          return {
            value: type.unit_id,
            text: type.unit_name
          };
        });
        this.advanceSearchForm.unitList = valueAndTextArr;
        this.selectedUnit =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedUnit = this.keyValue;
        this.advanceSearchForm.unitList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getSubUnitList(subUnitList) {
      if (subUnitList && subUnitList.length) {
        const valueAndTextArr = subUnitList.map(type => {
          return {
            value: type.sub_unit_id,
            text: type.sun_unit_name
          };
        });
        this.advanceSearchForm.subUnitList = valueAndTextArr;
        this.selectedSubUnit =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedSubUnit = this.keyValue;
        this.advanceSearchForm.unitList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },

    resetSearchForm() {
      this.selectedPaymentMode = this.keyValue;
      this.selectedDrawnOn = this.keyValue;
      this.selectedProject = this.keyValue;
      this.selectedSector = this.keyValue;
      this.selectedPocket = this.keyValue;
      this.selectedTower = this.keyValue;
      this.selectedFloor = this.keyValue;
      this.selectedUnit = this.keyValue;
      this.selectedSubUnit = this.keyValue;
      this.selectedInstrumentStatus = this.keyValue;
      this.gl_start_date = null;
      this.gl_end_date = null;
      this.creation_start_date = null;
      this.creation_end_date = null;
      this.value_start_date = null;
      this.value_end_date = null;
      this.valueDate = null;
      this.glDate = null;
      this.creationDate = null;
    },
    onSelect(event) {
      this.selectedReceiptAgainst = event;
    },
    receiptSearch() {
      const payload = {
        searchParams: {
          with_unit: this.selectedReceiptAgainst.value === 'SUBUNIT',
          le_id: this.selectedLeagalEntity.value,
          receipt_mode_vset: this.paymentMode,
          document_num: this.receiptNum,
          customer_name: this.customerName,
          bank_vset: this.selectedDrawnOn.value,
          prj_id: this.selectedProject.value,
          sector_id: this.selectedSector.value,
          pocket_id: this.selectedPocket.value,
          tower_id: this.selectedTower.value,
          floor_id: this.selectedFloor.value,
          unit_id: this.selectedUnit.value,
          sub_unit_id: this.selectedSubUnit.value,
          value_start_date: this.valueDate
            ? format(this.valueDate[0], appStrings.DATEFNSFORMAT)
            : null,
          value_end_date: this.valueDate
            ? format(this.valueDate[1], appStrings.DATEFNSFORMAT)
            : null,
          gl_start_date: this.glDate
            ? format(this.glDate[0], appStrings.DATEFNSFORMAT)
            : null,
          gl_end_date: this.glDate
            ? format(this.glDate[1], appStrings.DATEFNSFORMAT)
            : null,
          instrument_start_date: null,
          instrument_end_date: null,
          instrument_status_vset: this.selectedInstrumentStatus.value,
          party_receipt_method_id: null,
          instrument_start_amount: this.instrumentStartAmount,
          instrument_end_amount: this.instrumentEndAmount,
          creation_start_date: this.creationDate
            ? format(this.creationDate[0], appStrings.DATEFNSFORMAT)
            : null,
          creation_end_date: this.creationDate
            ? format(this.creationDate[1], appStrings.DATEFNSFORMAT)
            : null
        },
        pagination: {
          _page: this.currentPage - 1,
          _limit: this.perPage
        }
      };
      this.$emit('advanceSearch', payload);
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeout = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'PAYMENT_MODE') {
        this.advanceSearchForm.paymentMode.value = item.value_code;
        this.advanceSearchForm.paymentMode.text = item.value_meaning;
      } else if (this.vsetCode === 'BANK_NAME_VSET') {
        this.advanceSearchForm.drawnOn.value = item.value_code;
        this.advanceSearchForm.drawnOn.text = item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.advanceSearchForm.leagalEntity.value = item.org_id;
        this.advanceSearchForm.leagalEntity.text = item.org_name;
        this.getProjectList(item.org_id);
      } else if (this.vsetCode === 'FMS_SEARCH_BY') {
        this.advanceSearchForm.receiptAgainst.value = item.value_code;
        this.advanceSearchForm.receiptAgainst.text = item.value_meaning;
        this.selectedReceiptAgainst = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeout);
  }
};
