import { ModelSelect, BasicSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import store from '../../../../../store/';
import { format } from 'date-fns';
export default {
  name: 'createReceipt',
  components: {
    ModelSelect,
    DatePicker,
    BasicSelect
  },
  watch: {
    selectedApplicationType: function() {
      if (this.selectedApplicationType.value === 'AUTOMATIC') {
        this.calculateAdjustmentAmount();
      }
    },
    customerPerPage: function() {
      this.customerCurrentPage = 1;
      this.getOtherLovByProjectId();
    },
    selectedProject: function() {
      if (this.selectedProject.value) {
        this.lovFieldName = appStrings.FMSLOV.FMSPROJECT;
        this.lovId = this.selectedProject.value;
        this.getOtherLovByProjectId();
      }
    },
    selectReceiptNature: function() {
      this.unitDetailsVisible =
        this.selectReceiptNature.value === 'identified' &&
        this.createReceiptForm.leAndOuDetails.selectedReceiptAgainst.value ===
          'SUBUNIT'
          ? true
          : false;
      this.costomerDetailsVisible =
        this.selectReceiptNature.value === 'unidentified' ? false : true;
    }
  },
  data() {
    return {
      vsetCode: null,
      setTimeout: null,
      showValueSetModal: false,
      unsubscribe: null,
      valueSetModal: false,
      parent_value_set_id: null,
      advanceAmountWarning: false,
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showReceiptPreview: false,
      remainingAmout: null,
      totalOsAmout: null,
      showAdjWarning: false,
      warningMessage: null,
      customerPerPage: commonHelper.perPageRecord,
      customerCurrentPage: 1,
      customerTotalRows: null,
      unitDetailsVisible: true,
      costomerDetailsVisible: true,
      loader: false,
      openOuModal: false,
      lovFieldName: null,
      lovId: null,
      showCreateReceiptModel: false,
      dummyOptions: [
        {
          value: null,
          text: '--select--'
        }
      ],
      selectedDummy: {
        value: null,
        text: '--select--'
      },
      selectReceiptNature: {
        value: 'identified',
        text: 'Identified'
      },
      receiptNatureList: [
        {
          value: 'identified',
          text: 'Identified'
        },
        {
          value: 'unidentified',
          text: 'Unidentified'
        }
      ],
      keyValue: {
        value: null,
        text: null
      },
      keyValueNoRecord: {
        value: null,
        text: 'no record found'
      },
      selectedProject: {
        value: null,
        text: null
      },
      paymentModeList: [],
      receiptMethodList: [],
      receiptMethodNameList: [],
      selectedMethodName: {
        value: null,
        text: null
      },
      selectedPaymentMode: {
        value: null,
        text: null
      },
      bankList: [],
      selectedBank: {
        value: null,
        text: null
      },
      applicationTypeList: [],
      selectedApplicationType: {
        value: null,
        text: null
      },
      createReceiptForm: {
        fms_receipt_hdr_id: 0,
        leAndOuDetails: {
          legalEntity: {
            id: null,
            text: null
          },
          orgUnit: {
            id: null,
            text: null
          },
          receiptAgainstList: [
            {
              value: null,
              text: null
            }
          ],
          selectedReceiptAgainst: {
            value: null,
            text: null
          }
        },
        unitDetails: {
          prj_name: null,
          sector_name: null,
          pocket_name: null,
          tower_name: null,
          floor_name: null,
          unit_name: null,
          sub_unit_name: null,
          projectList: [],
          sectorList: [],
          selectedSector: {
            value: null,
            text: null
          },
          pocketList: [],
          selectedPocket: {
            value: null,
            text: null
          },
          towerList: [],
          selectedTower: {
            value: null,
            text: null
          },
          floorList: [],
          selectedFloor: {
            value: null,
            text: null
          },
          unitList: [],
          selectedUnit: {
            value: null,
            text: null
          },
          subUnitList: [],
          selectedSubUnit: {
            value: null,
            text: null
          }
        },
        customerDetails: {
          customer_name: null,
          customer_id: null,
          complete_address: null,
          address_id: null,
          site_name: null,
          customer_site_id: null,
          fms_sub_unit_id: null,
          fms_sub_unit_name: null
        },
        instrumentDetails: {
          receipt_num: null,
          currency_code: null,
          currency_id: null,
          exchange_rate: 1,
          value_date: format(new Date(), appStrings.DATEFNSFORMAT),
          gl_date: format(new Date(), appStrings.DATEFNSFORMAT),
          instrument_date: format(new Date(), appStrings.DATEFNSFORMAT),
          amount: null,
          instrument_num: null,
          branch: null,
          narration: null,
          instrument_status: null,
          clearing_date: null,
          reason: null
        },
        receiptMethodDetails: {
          auto_apply: true,
          party_id: 0,
          branch_name: null,
          bank_branch_id: null,
          account_name: null,
          bank_account_id: null,
          party_receipt_method_id: null,
          receipt_mrthods_name: null,
          bank_name: null
        }
      },
      outStandingDataList: [],
      modifiedOutStandingDataList: [],
      adJustmentDataList: [
        {
          component_group: null,
          component: null,
          amount: null,
          tax_category: null,
          tax_amount: null,
          selectedCompoentGroup: {
            vlaue: null,
            text: null
          },
          selectedCompoent: {
            value: null,
            text: null
          },
          componentGroupList: [],
          componentList: [],
          taxCategoryList: [],
          selectedCategory: {
            value: null,
            text: null
          },
          total_amount: null
        }
      ],
      componentGroupList: [],
      componentList: [],
      outStandingTableFields: [
        {
          key: 'line_type'
        },
        {
          key: 'document_num',
          label: 'Bill No'
        },
        {
          key: 'value_date'
        },
        {
          key: 'comp_group',
          label: 'Comp Group'
        },
        {
          key: 'comp',
          label: 'Component'
        },
        {
          key: 'tax'
        },
        {
          key: 'bill_amount',
          label: 'Bill Amt',
          class: 'text-right'
        },
        {
          key: 'bill_comp_id',
          class: 'd-none'
        },
        {
          key: 'bill_tax_id',
          class: 'd-none'
        },
        {
          key: 'applied_amt',
          label: 'App Amt',
          class: 'text-right'
        },
        {
          key: 'os_amount',
          label: 'OS Amt',
          class: 'text-right'
        },
        {
          key: 'adj_amount',
          label: 'Adj Amt',
          class: 'adj-amount-col'
        }
      ],
      adjustmentTableFields: [
        {
          key: 'component_group'
        },
        {
          key: 'component'
        },
        {
          key: 'tax_category'
        },
        {
          key: 'total_amount'
        },
        {
          key: 'amount'
        },
        {
          key: 'tax_amount'
        }
      ],
      fmsCompGroupList: [],
      showCustomerModal: false,
      customerName: null,
      customerList: [],
      customerFields: [
        {
          key: 'site_name'
        },
        {
          key: 'customer_site_id',
          class: 'd-none'
        },
        {
          key: 'customer_id',
          class: 'd-none'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'complete_address'
        },
        {
          key: 'address_id',
          class: 'd-none'
        }
      ],
      accountingDetailsList: [],
      accountdingDetailsField: [
        {
          key: 'line_type'
        },
        {
          key: 'cancatenated_segment'
        },
        {
          key: 'gl_date'
        },
        {
          key: 'period_name',
          label: 'Period'
        },
        {
          key: 'transaction_amt_dr'
        },
        {
          key: 'transaction_amt_cr'
        },
        {
          key: 'amount_dr'
        },
        {
          key: 'amount_cr'
        },
        {
          key: 'accounting_status'
        },
        {
          key: 'posting_status'
        }
      ]
    };
  },
  mounted() {
    this.getOrganizationList(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
    this.getReceiptAgainast();
    this.getCurrency();
    this.getReceiptApplicatonTypes();
    this.getAdjComponentGroupList();
    this.getTaxCategory();
    this.eventBus.$off('getReceiptId');
    this.eventBus.$on('getReceiptId', receiptHdrId => {
      this.loader = true;
      this.editMode = true;
      this.showCreateReceiptModel = true;
      this.createReceiptForm.fms_receipt_hdr_id = receiptHdrId;
      setTimeout(() => {
        this.getReceiptDetailsById(receiptHdrId);
      }, 500);
    });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAlert = false;
          this.editMode = false;
          this.showCreateReceiptModel = true;
        }
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = false;
        }
        if (actionName === 'save' && this.showCreateReceiptModel) {
          this.showReceiptPreview = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', {
            id: this.createReceiptForm.fms_receipt_hdr_id
          });
        }
      }
    });
  },
  methods: {
    addEditReceipt() {
      const filterAdvanceReceipt = this.adJustmentDataList.filter(
        advanceBill => advanceBill.amount !== null
      );
      const advanceReceipt = filterAdvanceReceipt.map(advanceBill => {
        return {
          amount: advanceBill.total_amount,
          comp_group_vset: advanceBill.selectedCompoentGroup.value,
          comp_vset: advanceBill.selectedCompoent.value,
          fms_receipt_dtl_id: 0,
          tax_cat_id: advanceBill.selectedCategory.value
        };
      });
      const payload = {
        fms_receipt_hdr_id: this.createReceiptForm.fms_receipt_hdr_id,
        le_id: this.createReceiptForm.leAndOuDetails.legalEntity.id,
        customer_id: this.unitDetailsVisible
          ? this.createReceiptForm.customerDetails.customer_id
          : null,
        customer_site_id: this.unitDetailsVisible
          ? this.createReceiptForm.customerDetails.customer_site_id
          : null,
        site_address_id: this.createReceiptForm.customerDetails.address_id
          ? this.createReceiptForm.customerDetails.address_id
          : null,
        currency_id: this.createReceiptForm.instrumentDetails.currency_id,
        application_type_vset: this.selectedApplicationType.value,
        auto_apply: this.createReceiptForm.receiptMethodDetails.auto_apply,
        bank_branch: this.createReceiptForm.instrumentDetails.branch,
        bank_vset: this.selectedBank.value,
        comments: this.createReceiptForm.instrumentDetails.narration,
        exchange_rate: this.createReceiptForm.instrumentDetails.exchange_rate,
        fms_sub_unit_id: this.createReceiptForm.unitDetails.selectedSubUnit
          .value,
        gl_date: format(
          new Date(this.createReceiptForm.instrumentDetails.gl_date),
          appStrings.DATEFNSFORMAT
        ),
        instrument_amt: this.createReceiptForm.instrumentDetails.amount,
        instrument_date: format(
          new Date(this.createReceiptForm.instrumentDetails.instrument_date),
          appStrings.DATEFNSFORMAT
        ),
        instrument_num: this.createReceiptForm.instrumentDetails.instrument_num,
        party_receipt_method_id: this.selectedMethodName.value,
        receipt_mode_vset: this.selectedPaymentMode.value,
        value_date: format(
          new Date(this.createReceiptForm.instrumentDetails.value_date),
          appStrings.DATEFNSFORMAT
        ),
        advance_receipts:
          this.unitDetailsVisible && this.modifiedOutStandingDataList.length > 0
            ? advanceReceipt
              ? advanceReceipt
              : null
            : null,
        outstanding_receipts: this.unitDetailsVisible
          ? this.modifiedOutStandingDataList.map(outStanding => {
              return {
                adj_amount: outStanding.adj_amount,
                bill_comp_id: outStanding.bill_comp_id,
                bill_hdr_id: outStanding.bill_hdr_id,
                bill_tax_id: outStanding.bill_tax_id,
                fms_receipt_adj_id:
                  outStanding.fms_receipt_adj_id === null
                    ? 0
                    : outStanding.fms_receipt_adj_id,
                trx_line_id: outStanding.trx_line_id
              };
            })
          : null
      };
      this.loader = true;
      this.$store
        .dispatch('fms/addEditReceipt', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.showReceiptPreview = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getReceiptDetailsById(receiptId) {
      this.$store
        .dispatch('fms/getFMSReceiptById', receiptId)
        .then(response => {
          this.loader = false;
          if (response.data.data) {
            const result = response.data.data;
            this.createReceiptForm.receiptMethodDetails.party_receipt_method_id =
              result.party_receipt_method_id;
            this.createReceiptForm.leAndOuDetails.legalEntity.text =
              result.le_name;
            this.createReceiptForm.leAndOuDetails.legalEntity.id = result.le_id;
            this.createReceiptForm.leAndOuDetails.orgUnit.text = result.ou_name;
            this.createReceiptForm.leAndOuDetails.orgUnit.id = result.ou_id;
            this.createReceiptForm.unitDetails.prj_name = result.prj_name;
            this.createReceiptForm.unitDetails.sector_name = result.sector_name;
            this.createReceiptForm.unitDetails.pocket_name = result.pocket_name;
            this.createReceiptForm.unitDetails.tower_name = result.tower_name;
            this.createReceiptForm.unitDetails.floor_name = result.floor_name;
            this.createReceiptForm.unitDetails.unit_name = result.unit_name;
            this.createReceiptForm.unitDetails.sub_unit_name =
              result.sub_unit_name;
            this.selectedProject = {
              value: result.prj_id,
              text: result.prj_name
            };
            this.createReceiptForm.unitDetails.selectedSector = {
              value: result.sector_id,
              text: result.sector_name
            };
            this.createReceiptForm.unitDetails.selectedPocket = {
              value: result.pocket_id,
              text: result.pocket_name
            };
            this.createReceiptForm.unitDetails.towerList = {
              value: result.tower_id,
              text: result.tower_name
            };
            this.createReceiptForm.unitDetails.selectedFloor = {
              value: result.floor_id,
              text: result.floor_name
            };
            this.createReceiptForm.unitDetails.selectedUnit = {
              value: result.unit_id,
              text: result.unit_name
            };
            this.createReceiptForm.unitDetails.selectedSubUnit = {
              value: result.sub_unit_id,
              text: result.sub_unit_name
            };
            this.createReceiptForm.customerDetails.customer_name =
              result.customer_name;
            this.createReceiptForm.customerDetails.site_name =
              result.customer_site_name;
            this.createReceiptForm.customerDetails.complete_address =
              result.bill_to_address;
            this.createReceiptForm.customerDetails.customer_id =
              result.customer_id;
            this.createReceiptForm.customerDetails.customer_site_id =
              result.customer_site_id;
            this.createReceiptForm.customerDetails.address_idcurrency_code =
              result.site_address_id;
            this.createReceiptForm.instrumentDetails.receipt_num =
              result.receipt_num;
            this.createReceiptForm.instrumentDetails.currency_code =
              result.currency;
            this.createReceiptForm.instrumentDetails.currency_id =
              result.currency_id;
            this.createReceiptForm.instrumentDetails.exchange_rate =
              result.exchange_rate;
            this.selectedPaymentMode = {
              value: result.payment_mode_vset,
              text: result.payment_mode
            };
            this.createReceiptForm.instrumentDetails.instrument_num =
              result.instrument_num;
            this.createReceiptForm.instrumentDetails.instrument_date =
              result.instrument_date;
            this.createReceiptForm.instrumentDetails.amount =
              result.instrument_amount;
            this.createReceiptForm.instrumentDetails.value_date =
              result.value_date;
            this.createReceiptForm.instrumentDetails.gl_date = result.gl_date;
            this.createReceiptForm.instrumentDetails.narration =
              result.narration;
            this.selectedBank = {
              value: result.bank_vset,
              text: result.bank
            };
            this.createReceiptForm.instrumentDetails.branch = result.branch;
            this.createReceiptForm.instrumentDetails.instrument_status =
              result.instrument_status;
            this.createReceiptForm.instrumentDetails.clearing_date =
              result.clearing_date;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    getReceiptAdvance() {
      const payload = {
        fms_receipt_hdr_id: this.createReceiptForm.fms_receipt_hdr_id
      };
      this.$store
        .dispatch('fms/getReceiptAdvanceData', payload)
        .then(response => {
          this.adJustmentDataList = response.data.data;
        });
    },
    getTaxCategory() {
      this.$store.dispatch('fms/getTaxCatory').then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const getValueAndText = results.map(type => {
            return {
              value: type.tax_cat_hdr_id,
              text: type.category_name
            };
          });
          this.adJustmentDataList[0].taxCategoryList = getValueAndText;
          this.adJustmentDataList[0].selectedCategory = getValueAndText[0];
        }
      });
    },
    getOrganizationList(orgType) {
      const payload = {
        valueSetName: orgType,
        dependentVariable: {}
      };
      if (orgType === appStrings.VALUESETTYPE.OU_LIST) {
        payload.dependentVariable.le_id = this.createReceiptForm.leAndOuDetails.legalEntity.id;
      } else {
        payload.dependentVariable.user_id = store.state.auth.userId;
        payload.dependentVariable.res_id = store.state.shared.responsibilityId;
      }
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          const result = response.data.data.data[0];
          if (orgType === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
            this.createReceiptForm.leAndOuDetails.legalEntity.text =
              result.org_name;
            this.createReceiptForm.leAndOuDetails.legalEntity.id =
              result.org_id;
            this.getProjectList();
            this.getReceiptCustomer();
          } else {
            this.createReceiptForm.leAndOuDetails.orgUnit.text =
              result.org_name;
            this.createReceiptForm.leAndOuDetails.orgUnit.id = result.org_id;
          }
          if (orgType === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
            this.getOrganizationList(appStrings.VALUESETTYPE.OU_LIST);
          }
        })
        .catch(err => {
          this.err = err;
        });
    },
    getReceiptCustomer() {
      this.customerList = [];
      const payload = {
        _page: this.customerCurrentPage - 1,
        _limit: this.customerPerPage,
        le_id: this.createReceiptForm.leAndOuDetails.legalEntity.id
          ? this.createReceiptForm.leAndOuDetails.legalEntity.id
          : null,
        fms_sub_unit_id: this.createReceiptForm.unitDetails.selectedSubUnit
          .value, // 1956,
        customer_name: this.customerName
      };
      if (!this.editMode) {
        this.createReceiptForm.customerDetails = {
          customer_name: null,
          customer_id: null,
          complete_address: null,
          address_id: null,
          site_name: null,
          customer_site_id: null,
          fms_sub_unit_id: null,
          fms_sub_unit_name: null
        };
        this.$store
          .dispatch('fms/getCustomerDetail', payload)
          .then(response => {
            if (response.status === 200) {
              const result = response.data.data;
              if (result.page && result.page.length > 0) {
                this.customerList = result.page;
                this.customerTotalRows = result.total_page;
                this.createReceiptForm.customerDetails = result.page[0];
              }
            }
          });
      }
    },
    getCurrency() {
      const payload = {
        currentPage: 0,
        perPage: 10,
        search: 'inr'
      };
      this.$store
        .dispatch('currency/getCurrencyPagination', payload)
        .then(response => {
          const results = response.data.data.page;
          if (results) {
            this.createReceiptForm.instrumentDetails.currency_code =
              results[0].currency_code;
            this.createReceiptForm.instrumentDetails.currency_id =
              results[0].currency_id;
          }
        });
    },
    getPaymentMode() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'PAYMENT_MODE')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.paymentModeList = getValueAndText;
          }
        });
    },
    getReceiptMethodsData() {
      this.receiptMethodList = [];
      this.receiptMethodNameList = [];
      this.createReceiptForm.receiptMethodDetails.bank_name = null;
      this.createReceiptForm.receiptMethodDetails.branch_name = null;
      this.createReceiptForm.receiptMethodDetails.account_name = null;
      this.createReceiptForm.receiptMethodDetails.auto_apply = false;
      const payload = {
        le_id: this.createReceiptForm.leAndOuDetails.legalEntity.id
          ? this.createReceiptForm.leAndOuDetails.legalEntity.id
          : null,
        fms_project_id: this.selectedProject.value
          ? this.selectedProject.value
          : null,
        receipt_mode_vset_code:
          this.selectedPaymentMode.value !== null
            ? this.selectedPaymentMode.value
            : null,
        party_receipt_method_id: this.createReceiptForm.receiptMethodDetails
          .party_receipt_method_id
      };
      if (!this.checkIntrumentFomrValidation()) {
        this.loader = true;
        this.$store
          .dispatch('fms/getReceiptMethods', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              const result = response.data.data;
              this.receiptMethodList = result;
              this.receiptMethodNameList = response.data.data.map(method => {
                return {
                  value: method.party_receipt_method_id,
                  text: method.receipt_mrthods_name
                };
              });
              if (this.receiptMethodList.length > 0) {
                this.getSelectedMethodNameData(this.receiptMethodList);
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getReceiptApplicatonTypes() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'RECEIPT_APPLICATION_TYPE')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.applicationTypeList = getValueAndText;
            this.selectedApplicationType =
              getValueAndText && getValueAndText.length > 0
                ? getValueAndText[0]
                : this.keyValue;
          }
        });
    },
    getOutStandingList() {
      const payload = {
        fms_sub_unit_id: this.createReceiptForm.unitDetails.selectedSubUnit
          .value,
        fms_receipt_hdr_id: this.createReceiptForm.fms_receipt_hdr_id
      };
      this.$store.dispatch('fms/getOutStandinglist', payload).then(response => {
        if (response.status === 200) {
          if (this.editMode) {
            this.modifiedOutStandingDataList =
              response.data.data.fms_outstandings;
          } else {
            this.outStandingDataList = response.data.data.fms_outstandings;
          }
          this.totalOsAmout = response.data.data.total_os_amt;
        }
      });
    },
    selectedRecpMethodFun(event) {
      this.selectedMethodName.value = event.value;
      this.selectedMethodName.text = event.text;
      this.getSelectedMethodNameData(this.receiptMethodList);
    },
    getSelectedMethodNameData(receiptMethodList) {
      if (receiptMethodList && receiptMethodList.length > 1) {
        const findReceiptMethodObj = receiptMethodList.find(
          method =>
            method.party_receipt_method_id === this.selectedMethodName.value
        );
        if (findReceiptMethodObj) {
          this.createReceiptForm.receiptMethodDetails = findReceiptMethodObj;
        }
      } else {
        this.createReceiptForm.receiptMethodDetails =
          receiptMethodList && receiptMethodList[0];
        this.selectedMethodName.value =
          receiptMethodList && receiptMethodList[0].party_receipt_method_id;
        this.selectedMethodName.text =
          receiptMethodList && receiptMethodList[0].receipt_mrthods_name;
      }
    },
    selectedComGroup(event, index) {
      this.adJustmentDataList[index].selectedCompoentGroup = event;
      const findValueSet = this.fmsCompGroupList.find(
        compGroup => compGroup.value_code === event.value
      );
      this.getAdjComponentList(findValueSet.value_set_dtl_id, index);
    },
    selectedCustomerRow() {},
    openCustomerModal(flag) {
      this.showCustomerModal = flag;
    },
    hideModel() {
      this.showCreateReceiptModel = false;
      this.showReceiptPreview = false;
      this.$emit('hideCreateReceiptComp');
    },
    selectedTowerFun(towerVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSTOWER;
      this.lovId = towerVal.value;
      this.createReceiptForm.unitDetails.selectedTower = towerVal;
      this.getOtherLovByProjectId();
    },
    selectedFloorFun(floorVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSFLOOR;
      this.createReceiptForm.unitDetails.selectedFloor = floorVal;
      this.lovId = floorVal.value;
      this.getOtherLovByProjectId();
    },
    selectedUnitFun(unitVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSUNIT;
      this.createReceiptForm.unitDetails.selectedUnit = unitVal;
      this.lovId = unitVal.value;
      this.getOtherLovByProjectId();
    },
    selectedSubUnitFun(subUnitVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSSUBUNIT;
      this.createReceiptForm.unitDetails.selectedSubUnit = subUnitVal;
      this.lovId = subUnitVal.value;
      this.getOutStandingList();
      this.getOtherLovByProjectId();
      this.getReceiptCustomer();
    },
    getReceiptAgainast() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'FMS_SEARCH_BY')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.createReceiptForm.leAndOuDetails.receiptAgainstList = getValueAndText;
            this.createReceiptForm.leAndOuDetails.selectedReceiptAgainst =
              getValueAndText && getValueAndText.length
                ? getValueAndText[0]
                : this.selectedReceiptAgainst;
          }
        });
    },
    getBankList() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'BANK_NAME_VSET')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.bankList = getValueAndText;
          }
        });
    },
    getAdjComponentGroupList() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'FMS_COMP_GROUP')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.fmsCompGroupList = results;
            this.componentGroupList = getValueAndText;
            this.adJustmentDataList[0].componentGroupList = this.componentGroupList;
            this.adJustmentDataList[0].selectedCompoentGroup = this.componentGroupList[0];
            this.selectedComGroup(this.componentGroupList[0], 0);
          }
        });
    },
    getAdjComponentList(valueSetId, index) {
      const payload = {
        lovType: 'FMS_COMP',
        parent_value_set_id: valueSetId
      };
      this.$store.dispatch('party/getLOVBySetType', payload).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const getValueAndText = results.map(type => {
            return {
              value: type.value_code,
              text: type.value_meaning
            };
          });
          this.componentList = getValueAndText;
          this.adJustmentDataList[index].componentList = getValueAndText;
          this.adJustmentDataList[index].selectedCompoent = getValueAndText[0];
        }
      });
    },
    selectReceiptAgainst(option) {
      this.createReceiptForm.leAndOuDetails.selectedReceiptAgainst = option;
      this.unitDetailsVisible =
        this.selectReceiptNature.value === 'identified' &&
        option.value === 'SUBUNIT'
          ? true
          : false;
    },
    getProjectList() {
      const payload = {
        typeOfProject: 'FMSPRJ',
        le_id: this.createReceiptForm.leAndOuDetails.legalEntity.id
      };
      this.$store.dispatch('fms/getProjectList', payload).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const getValueAndText = results.map(type => {
            return {
              value: type.proj_id,
              text: type.proj_name
            };
          });
          this.createReceiptForm.unitDetails.projectList = getValueAndText;
        }
      });
    },
    getOtherLovByProjectId() {
      this.loader = true;
      const payload = {
        lovFieldName: this.lovFieldName,
        lovId: this.lovId
      };
      this.$store
        .dispatch('receipt/getOtherLovByLovFieldAndId', payload)
        .then(response => {
          this.loader = false;
          if (response && response.data && response.data.data) {
            const results = response.data.data;
            if (this.lovFieldName === appStrings.FMSLOV.FMSPROJECT) {
              this.getSectorList(results.sectors);
              this.getPocketList(results.pockets);
              this.getTowerList(results.towers);
              this.getFloorList(results.floors);
              this.getUnitList(results.units);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSTOWER) {
              this.getFloorList(results.floors);
              this.getUnitList(results.units);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSFLOOR) {
              this.getTowerList(results.towers);
              this.getUnitList(results.units);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSUNIT) {
              this.getTowerList(results.towers);
              this.getFloorList(results.floors);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSSUBUNIT) {
              this.getTowerList(results.towers);
              this.getFloorList(results.floors);
              this.getUnitList(results.units);
            }
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    getSectorList(sectorList) {
      if (sectorList && sectorList.length) {
        const valueAndTextArr = sectorList.map(type => {
          return {
            value: type.sector_id,
            text: type.sector_name
          };
        });
        this.createReceiptForm.unitDetails.sectorList = valueAndTextArr;
        this.createReceiptForm.unitDetails.selectedSector =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.createReceiptForm.unitDetails.selectedSector = this.keyValueNoRecord;
        this.createReceiptForm.unitDetails.sectorList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getPocketList(pocketList) {
      if (pocketList && pocketList.length) {
        const valueAndTextArr = pocketList.map(type => {
          return {
            value: type.pocket_id,
            text: type.pocket_name
          };
        });
        this.createReceiptForm.unitDetails.pocketList = valueAndTextArr;
        this.createReceiptForm.unitDetails.selectedPocket =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.createReceiptForm.unitDetails.selectedPocket = this.keyValueNoRecord;
        this.createReceiptForm.unitDetails.pocketList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getTowerList(towerList) {
      if (towerList && towerList.length) {
        const valueAndTextArr = towerList.map(type => {
          return {
            value: type.tower_id,
            text: type.tower_name
          };
        });
        this.createReceiptForm.unitDetails.towerList = valueAndTextArr;
        this.createReceiptForm.unitDetails.selectedTower =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.createReceiptForm.unitDetails.selectedTower = this.keyValueNoRecord;
        this.createReceiptForm.unitDetails.towerList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getFloorList(floorList) {
      if (floorList && floorList.length) {
        const valueAndTextArr = floorList.map(type => {
          return {
            value: type.floor_id,
            text: type.floor_name
          };
        });
        this.createReceiptForm.unitDetails.floorList = valueAndTextArr;
        this.createReceiptForm.unitDetails.selectedFloor =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.createReceiptForm.unitDetails.selectedFloor = this.keyValueNoRecord;
        this.createReceiptForm.unitDetails.floorList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getUnitList(unitList) {
      if (unitList && unitList.length) {
        const valueAndTextArr = unitList.map(type => {
          return {
            value: type.unit_id,
            text: type.unit_name
          };
        });
        this.createReceiptForm.unitDetails.unitList = valueAndTextArr;
        this.createReceiptForm.unitDetails.selectedUnit =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.createReceiptForm.unitDetails.selectedUnit = this.keyValueNoRecord;
        this.createReceiptForm.unitDetails.unitList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getSubUnitList(subUnitList) {
      if (subUnitList && subUnitList.length) {
        const valueAndTextArr = subUnitList.map(type => {
          return {
            value: type.sub_unit_id,
            text: type.sun_unit_name
          };
        });
        this.createReceiptForm.unitDetails.subUnitList = valueAndTextArr;
        this.createReceiptForm.unitDetails.selectedSubUnit =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.createReceiptForm.unitDetails.selectedSubUnit;
      } else {
        this.createReceiptForm.unitDetails.selectedSubUnit = this.keyValueNoRecord;
        this.createReceiptForm.unitDetails.unitList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    calculateAdjustmentAmount() {
      if (!this.editMode) {
        if (this.selectedApplicationType.value === 'AUTOMATIC') {
          this.remainingAmout = this.createReceiptForm.instrumentDetails.amount;
          this.modifiedOutStandingDataList = this.outStandingDataList.map(
            osData => {
              const adjAmount =
                this.remainingAmout >= parseFloat(osData.os_amount)
                  ? osData.os_amount
                  : 0.0;
              if (this.remainingAmout >= parseFloat(osData.os_amount)) {
                this.remainingAmout =
                  this.remainingAmout - parseFloat(osData.os_amount);
              }
              return {
                trx_line_id: osData.trx_line_id,
                adj_amount: adjAmount,
                applied_amt: osData.applied_amt,
                bill_hdr_id: osData.bill_hdr_id,
                bill_id: osData.bill_id,
                bill_comp_id: osData.bill_comp_id,
                bill_tax_id: osData.bill_tax_id,
                comp: osData.comp,
                comp_group: osData.comp_group,
                document_num: osData.document_num,
                fms_receipt_adj_id: osData.fms_receipt_adj_id,
                line_type: osData.line_type,
                os_amount: osData.os_amount,
                tax: osData.tax,
                transaction_amt: osData.transaction_amt,
                value_date: osData.value_date,
                bill_amount: (osData.os_amount + osData.applied_amt).toFixed(2)
              };
            }
          );
        }
      } else {
        this.getOutStandingList();
      }
    },
    updateAjdAmount(event, index) {
      const osAmout = parseFloat(this.outStandingDataList[index].os_amount);
      const adjAmout = parseFloat(event.target.value);
      if (!isNaN(adjAmout)) {
        if (adjAmout > osAmout) {
          this.showAdjWarning = true;
          this.warningMessage =
            'Adjustable amount can not be greater then Outstanding amount';
        } else {
          this.showAdjWarning = false;
          this.remainingAmout =
            this.createReceiptForm.instrumentDetails.amount -
            this.getTotalAdjAmout();
        }
      } else if (event.target.value === '' || event.target.value === null) {
        this.remainingAmout = this.remainingAmout + osAmout;
      }
    },
    getTotalAdjAmout() {
      return this.outStandingDataList.reduce((totalValue, currentValue) => {
        return totalValue + currentValue.adj_amount;
      }, 0);
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    setRemainingAmount($event) {
      this.remainingAmout = $event.target.value;
    },
    calculateAdvanceAmount() {
      if (
        this.remainingAmout > 0 &&
        this.remainingAmout !== null &&
        !this.editMode
      ) {
        this.adJustmentDataList[0].amount =
          parseFloat(this.remainingAmout) -
          (parseFloat(this.remainingAmout) * 18) / 100;
        this.adJustmentDataList[0].tax_amount =
          (parseFloat(this.remainingAmout) * 18) / 100;
        this.adJustmentDataList[0].total_amount = this.remainingAmout;
      } else {
        this.getReceiptAdvance();
      }
    },
    checkIntrumentFomrValidation() {
      let validate = false;
      if (this.selectedPaymentMode.value !== 'CSH') {
        validate =
          this.createReceiptForm.instrumentDetails.instrument_num === null ||
          this.selectedBank.value === null;
      }
      return (
        (!this.editMode && !this.selectedPaymentMode.value) ||
        validate ||
        this.createReceiptForm.instrumentDetails.amount === null
      );
    },
    getReceiptAccountingList() {
      const payload = {
        fms_receipt_hdr_id: this.createReceiptForm.fms_receipt_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('fms/getReceiptAccounting', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.accountingDetailsList = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateAdvanceAmount(event) {
      const amount = parseFloat(event.target.value);
      this.advanceAmountWarning = false;
      if (amount > this.remainingAmout) {
        this.advanceAmountWarning = true;
        this.warningMessage =
          'Total amount can not be greate than remaining amount';
        this.calculateAdjustmentAmount();
      } else {
        this.adJustmentDataList[0].amount =
          parseFloat(amount) - (parseFloat(amount) * 18) / 100;
        this.adJustmentDataList[0].tax_amount = (parseFloat(amount) * 18) / 100;
        this.adJustmentDataList[0].total_amount = amount;
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode == appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.createReceiptForm.leAndOuDetails.legalEntity.value;
      } else {
        this.parent_value_set_id = null;
      }
      this.showValueSetModal = true;
      this.setTimeout = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'PAYMENT_MODE') {
        this.selectedPaymentMode.value = item.value_code;
        this.selectedPaymentMode.text = item.value_meaning;
      } else if (this.vsetCode === 'BANK_NAME_VSET') {
        this.selectedBank.value = item.value_code;
        this.selectedBank.text = item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.createReceiptForm.leAndOuDetails.legalEntity.value = item.org_id;
        this.createReceiptForm.leAndOuDetails.legalEntity.text = item.org_name;
        this.getProjectList();
        this.getReceiptCustomer();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.createReceiptForm.leAndOuDetails.orgUnit.text = item.org_name;
        this.createReceiptForm.leAndOuDetails.orgUnit.value = item.org_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    outStandingRowSelected() {}
  },
  destroyed() {
    this.$store.dispatch('shared/setUnsetOrgType', null);
  },
  beforeDestroy() {
    clearTimeout(this.setTimeout);
    this.unsubscribe();
  }
};
