import { ModelSelect, BasicSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import EditViewReceipt from './editViewReceipt';
import commonHelper from '@/app/utility/common.helper.utility';
import CreateReceipt from './createReceipt/';
import AdvanceSearchModal from './advanceSearchModal';
// import { format } from 'date-fns';
export default {
  name: 'Receipt',
  components: {
    DatePicker,
    ModelSelect,
    EditViewReceipt,
    BasicSelect,
    CreateReceipt,
    AdvanceSearchModal
  },
  watch: {
    currentPage: function() {
      this.searchParameter.pagination = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.advanceSearch(this.searchParameter);
    },
    perPage: function() {
      this.currentPage = 1;
      this.searchParameter.pagination = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.advanceSearch(this.searchParameter);
    }
  },
  data() {
    return {
      unsubscribe: null,
      searchParameter: null,
      receiptNum: '',
      rcptDateFromTo: [],
      rcptToDate: null,
      rcptFromDate: null,
      dateRangePicker: {
        opens: true,
        autoApply: true,
        dateRange: null,
        dateFormat: appStrings.DATEFNSFORMAT
      },
      showCreateReceipt: false,
      loader: false,
      lovFieldName: '',
      lovId: '',
      isAdvanceSearchEnable: false,
      search: '',
      data: [],

      update: false,
      showAlert: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,

      stickyHeader: true,
      totalRows: null,
      filterOn: [],
      showModal: false,
      editMode: false,
      responseMsg: '',
      isSuccess: false,
      isFailed: false,
      fields: [
        {
          key: 'project',
          class: 'text-center'
        },
        {
          key: 'unit_name',
          class: 'text-center'
        },
        {
          key: 'sub_unit_name',
          class: 'text-center'
        },
        {
          key: 'customer_name',
          class: 'text-center'
        },
        {
          key: 'receipt_number',
          class: 'text-center'
        },
        {
          key: 'receipt_date',
          class: 'text-center'
        },
        {
          key: 'value_date',
          class: 'text-center'
        },
        {
          key: 'gl_date',
          class: 'text-center'
        },
        {
          key: 'process_gl_date',
          class: 'text-center'
        },
        {
          key: 'payment_mode',
          class: 'text-center'
        },
        {
          Key: 'instrument_bank',
          class: 'text-center'
        },
        {
          key: 'instrument_number',
          class: 'text-center'
        },
        {
          key: 'instrument_date',
          class: 'text-center'
        },
        {
          key: 'instrument_amount'
        },
        {
          key: 'instrument_status',
          class: 'text-center'
        },
        {
          key: 'bank_account_name',
          class: 'text-center'
        },
        {
          key: 'clearing_date',
          class: 'text-center'
        },
        {
          key: 'clearning_gl_date',
          class: 'text-center'
        },
        {
          key: 'currency',
          class: 'text-center'
        },
        {
          key: 'legal_entity',
          class: 'text-center'
        },
        {
          key: 'sector',
          class: 'text-center'
        },
        {
          key: 'pocket',
          class: 'text-center'
        },
        {
          key: 'tower',
          class: 'text-center'
        },
        {
          key: 'floor',
          class: 'text-center'
        },
        {
          key: 'comments',
          class: 'text-center'
        },
        {
          key: 'reversal_comments',
          class: 'text-center'
        },
        {
          key: 'fms_receipt_hdr_id',
          class: 'd-none'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'add') {
          this.showCreateReceipt = true;
        }
        if (
          actionName === 'download' &&
          !this.showCreateReceipt &&
          !this.isAdvanceSearchEnable
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.searchParameter };
          downloadpayload.pagination._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'fms/fmsFilter',
            'reciept',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    enableAdvanceSearch() {
      this.isAdvanceSearchEnable = !this.isAdvanceSearchEnable;
    },
    rowSelected(item) {
      this.showCreateReceipt = true;
      setTimeout(() => {
        this.eventBus.$emit('getReceiptId', item.fms_receipt_hdr_id);
      }, 0);
    },
    advanceSearch(payload) {
      if (payload) {
        this.searchParameter = payload;
      } else {
        this.searchParameter = {
          pagination: {
            _page: this.currentPage - 1,
            _limit: this.perPage
          },
          searchParams: {
            document_num: this.receiptNum,
            creation_start_date: commonHelper.formattedDate(this.rcptDateFromTo[0]),
            creation_end_date: commonHelper.formattedDate(this.rcptDateFromTo[1]),
            // creation_end_date: this.rcptToDate
            //   ? format(new Date(this.rcptToDate), 'dd-MMM-yyyy')
            //   : null,
            // creation_start_date: this.rcptFromDate
            //   ? format(new Date(this.rcptFromDate), 'dd-MMM-yyyy')
            //   : null
          }
        };
      }
      this.loader = true;
      this.$store
        .dispatch('fms/fmsFilter', this.searchParameter)
        .then(response => {
          this.isAdvanceSearchEnable = false;
          this.loader = false;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    clearSearch() {
      this.rcptDateFromTo = [];
      // this.rcptToDate = null;
      // this.rcptFromDate = null;
      this.receiptNum = '';
      this.data = [];
    },
    hideCreateReceiptComp() {
      this.showCreateReceipt = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
